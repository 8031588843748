<template>
    <el-dialog
        title="预览"
        :visible.sync="dialogVisible"
        width="fit-content"
        :before-close="handleClose">
        <div class="priceTicketComp">
            <div class="priceTickt">
                <div class="ourPrice">
                    <div>本店价RMB</div>
                    <div>
                        <span>￥</span>
                        {{goodsObj.ourShopPrice}}
                    </div>
                </div>
                <div class="line">
                    <div class="item whole">
                        <div class="name">商品信息
                            <span>Trade name</span>
                        </div>
                        <!-- <div class="value">布木恋语  中古风  华夫格造型床  北美进口白蜡木  亲肤细腻仿真皮  银色不锈钢床脚  1.8*2.0米皮艺床（搭配10公分松木排骨）</div> -->
                        <div class="value">{{goodsObj.name}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="item whole">
                        <div class="name">商品型号
                            <span>Model</span>
                        </div>
                        <!-- <div class="value">BMLY-8030-FZP 1.8-C</div> -->
                        <div class="value">{{goodsObj.sn}}</div>
                    </div>
                    <div class="item whole">
                        <div class="name">品牌
                            <span>Brand</span>
                        </div>
                        <div class="value">{{goodsObj.brandName}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="item whole">
                        <div class="name">材质
                            <span>Material</span>
                        </div>
                        <!-- <div class="value">仿真皮+进口白蜡木+高密度海绵+不锈钢脚</div> -->
                        <div class="value">{{goodsObj.productMaterial}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="item whole">
                        <div class="name">商品尺寸(mm)
                            <span>Size</span>
                        </div>
                        <!-- <div class="value">长2160*宽1920*高1090mm</div> -->
                        <div class="value">{{goodsObj.productDimension}}</div>
                    </div>
                </div>
                <div class="btm">
                    <div class="radian"></div>
                    <div class="website">
                        <!-- <div>{{websiteName}}</div> -->
                        <div class="logo">
                            <img :src="websiteLogo+'?t='+(new Date().getTime())" crossorigin="anonymous"/>
                        </div>
                        <!-- <div>
                            <span></span>
                            全屋家居定制
                            <span></span>
                        </div> -->
                    </div>
                    <!-- <div class="right">
                        <img src="../assets/imgs/place52.png"/>
                        <img :src="goodsObj.cover+'?t='+(new Date().getTime())" crossorigin="anonymous"/>
                    </div> -->
                </div>
            </div>
            <div class="ope">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="clickPrint">打 印</el-button>
                <el-button type="primary" @click="clickConfirm">导 出</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props:['goodsObj','dialogVisible'],
    data(){
        return{
            websiteName:"",
            websiteLogo:"",
        }
    },
    methods: {
        handleClose(done){
            // done();
            this.$emit("close")
        },
        clickConfirm(){
            // this.downloadPDF();
            this.printPDF();
        },
        downloadPDF() {
            const element = document.querySelector(".priceTickt"); // 要转换为PDF的HTML元素
            html2canvas(element).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jspdf.jsPDF({
                    orientation: 'landscape',
                });
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('download.pdf');
                // document.body.appendChild(canvas)
            });
        },
        printPDF() {
            const element = document.querySelector(".priceTickt"); // 要转换为PDF的页面元素
            html2canvas(element,{useCORS:true,allowTaint:false}).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                // document.body.appendChild(canvas)
                const pdf = new jspdf.jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: [canvas.width, canvas.height]
                });
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('download.pdf'); // 保存的PDF文件名
            });
        },
        clickPrint(){
            const element = document.querySelector(".priceTickt"); // 要转换为PDF的页面元素
            html2canvas(element,{useCORS:true,allowTaint:false}).then((canvas) => {
                var iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                var doc = iframe.contentWindow.document;
                doc.open();
                doc.write('<html><head><title>Print</title>');
                doc.write('</head><body>');
                // doc.write(html);
                doc.write('</body></html>');
                doc.body.appendChild(canvas);
                doc.close();
                iframe.contentWindow.focus();
                iframe.contentWindow.print();

                setTimeout(()=>{
                    document.body.removeChild(iframe);
                },2000)
            })
        }
    },
    created() {
        if(sessionStorage.websiteName){
            this.websiteName=sessionStorage.websiteName;
        }else{
            // this.websiteName="奥格雅"
        }
        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo;
        }
    },
}
</script>

<style scoped>
.priceTicketComp{
    /* // width:978px; */
    width: 1181px;
}
.priceTicketComp .priceTickt{
    width: 100%;
}
.priceTicketComp .priceTickt .ourPrice{
    padding: 55px 0 80px 0;
    /* // margin: 0 55px; */
    margin: 0 140px;
}
.priceTicketComp .priceTickt .ourPrice>div:nth-child(1){
    font-size: 73px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
}
.priceTicketComp .priceTickt .ourPrice>div:nth-child(2){
    font-size: 158px;
    font-weight: 900;
    color: rgba(200, 64, 57, 1);
}
.priceTicketComp .priceTickt .ourPrice>div:nth-child(2)>span{
    font-size: 66px;
    margin-right: -50px;
}
.priceTicketComp .priceTickt .line{
    margin: 0 140px;
    border-top: 1px solid rgba(128, 128, 128, 1);
    display: flex;
    align-items: center;
    padding: 30px 0;
}
.priceTicketComp .priceTickt .line .item{
    width: 50%;
}
.priceTicketComp .priceTickt .line .item.whole{
    width: 100%;
}
.priceTicketComp .priceTickt .line .item .name{
    font-size: 42px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}
.priceTicketComp .priceTickt .line .item .name>span{
    color: rgba(128, 128, 128, 1);
}
.priceTicketComp .priceTickt .line .item .value{
    margin-top: 20px;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 46.34px;
    color: rgba(51, 51, 51, 1);
}
.priceTicketComp .priceTickt .btm{
    /* // height: 478px; */
    height: 372px;
    /* background: rgba(37, 126, 93, 1); */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.priceTicketComp .priceTickt .btm .website{
}
.priceTicketComp .priceTickt .btm .website>div:nth-child(1){
    margin-top: 120px;
    font-size: 135px;
    font-weight: 700;
    color: #fff;
}
.priceTicketComp .priceTickt .btm .website>div:nth-child(1)>img{
    /* // width: 387px;
    // max-height: 150px; */
    width: 517px;
    height: 170px;
    object-fit: cover;
}
.priceTicketComp .priceTickt .btm .website>div:nth-child(2){
    margin-top: -15px;
    font-size: 47px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.priceTicketComp .priceTickt .btm .website>div:nth-child(2)>span{
    height: 3px;
    background: #fff;
    width: 35px;
    margin: 0 8px;
    margin-top: 5px;
}
.priceTicketComp .priceTickt .btm .right{
}
.priceTicketComp .priceTickt .btm .right>img{
    width: 200px;
    height: 200px;
}
.priceTicketComp .priceTickt .btm .radian{
    position: absolute;
    /* // top: calc(-491px + 62px);
    // left: calc(calc(-1478px + 978px) / 2);
    // width: 1478px;
    // height: 491px; */
    top: calc(-660px + 87px);
    left: calc(calc(-1755px + 1181px) / 2);
    width: 1755px;
    height: 660px;
    background: #fff;
    /* // border-radius: calc(1478px / 2); */
    border-radius: 50%;
}
.priceTicketComp .ope{
    margin-top: 30px;
    text-align: right;
}
</style>